import $ from 'jquery'
import 'slick-carousel'
window.jQuery = window.$ = $;

import "slick-carousel";
// import "slick-carousel/slick/slick.css";  // Add this line to import the slick CSS
// import "slick-carousel/slick/slick-theme.css";  // Add this line to import the slick CSS
// import "slick-carousel/slick/slick.min.js";  // Ensure the JS file is correctly imported

import "@fancyapps/fancybox";
import jQueryBridget from "jquery-bridget";
import InfiniteScroll from "infinite-scroll";
// Import our CSS
import "@/scss/app.scss";

// make Infinite Scroll a jQuery plugin
jQueryBridget("infiniteScroll", InfiniteScroll, jQuery);

var formStatus = true;
var removeSelector = "";

/*document.addEventListener('afterBlitzInjectAll', function (event) {
	fetch('/actions/blitz/csrf/token')
	    .then(result => { return result.text(); })
	    .then(result => {
	    	var inputs = document.querySelectorAll("input[name='CRAFT_CSRF_TOKEN']");
	    	for (var i = 0; i < inputs.length; i++) {
	    		console.log(result);
	    		inputs[i].value = result;
	    	}
	    });
});*/

/* Script on ready
------------------------------------------------------------------------------*/
jQuery(function() {
    //do jQuery stuff when DOM is ready

    /* Responsive Jquery Navigation */
    $(document).on("click", ".hamburger", function () {
        $(".mbnav").toggleClass("left-0");
        $(".mbnav").toggleClass("-left-full");
    });
    $(document).on("click", ".mbbg", function () {
        $(".mbnav").toggleClass("-left-full");
    });

    /* menu open and close on single click */
    $(document).on("click", ".mbnav .has-sub>.down-arrow", function () {
        var element = $(this).parent("li");
        if (element.hasClass("is-open")) {
            element.removeClass("is-open");
            element.find("li").removeClass("is-open");
            element.find("ul").slideUp(200);
        } else {
            element.addClass("is-open");
            element.children("ul").slideDown(200);
            element.siblings("li").children("ul").slideUp(200);
            element.siblings("li").removeClass("is-open");
            element.siblings("li").find("li").removeClass("is-open");
            element.siblings("li").find("ul").slideUp(200);
        }
    });

    $(document).on("click", ".cart-open-link", function () {
        if ($(".cart-container-wrapper").hasClass("active")) {
            $(".cart-container-wrapper").stop().removeClass("active").fadeOut();
        } else {
            $(".cart-container-wrapper").stop().addClass("active").fadeIn();
        }
    });
    $(document).on("click", ".cart-close-link", function () {
        $(".cart-container-wrapper").stop().removeClass("active").fadeOut();
    });

    $(document).on("click", "body", function (e) {
        if (
            !$(e.target).closest(".cart-container-wrapper").length &&
            !$(e.target).hasClass("cart-open-link") &&
            $(window).width() > 768
        ) {
            $(".cart-container-wrapper").stop().removeClass("active").fadeOut();
        }
    });

    /* product-slider */
    if ($(".product-slider").length > 0) {
        $(".product-slider").slick({
            arrows: false,
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    }

    if ($(".product-slider-2").length > 0) {
        $(".product-slider-2").slick({
            arrows: false,
            dots: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 540,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    }

    /* carousel-single */
    if ($(".carousel-single").length > 0) {
        $(".carousel-single").slick({
            arrows: false,
            dots: true,
            infinite: true,
        });
    }

    if ($(".hero-banner").length > 0) {
        $(".hero-banner").slick({
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            prevArrow:
                '<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-12 h-12 absolute top-1/2 left-0 sm:left-4 opacity-75 hover:opacity-100 z-10 transform -translate-y-1/2" aria-label="Prev"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="m32 239 136-136c9-9 24-9 34 0l22 23c10 9 10 24 0 33l-96 97 96 96c10 10 10 25 0 34l-22 23c-10 9-25 9-34 0L32 273c-10-9-10-25 0-34z"/></svg></button>',
            nextArrow:
                '<button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-12 h-12 absolute top-1/2 right-0 sm:right-4 rotate-180 opacity-75 hover:opacity-100 z-10 transform -translate-y-1/2" aria-label="Next"><svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="m32 239 136-136c9-9 24-9 34 0l22 23c10 9 10 24 0 33l-96 97 96 96c10 10 10 25 0 34l-22 23c-10 9-25 9-34 0L32 273c-10-9-10-25 0-34z"/></svg></button>',
        }).on('setPosition', function (event, slick) {
            setSlideHeight();
        });

        // Recalculate height on window resize
        $(window).on('resize', function() {
            $('.hero-banner .slick-slide').css('height', 'auto'); // Reset to auto height
            setSlideHeight(); // Recalculate and set height
        });
    }

    $(document).on("click", ".remove-button", function (event) {
        event.preventDefault();
        removeSelector = $(this);
        removeSelector.prev("input").val("1");
        $(this).parents("form:first").submit();
    });

    /*$(document).on('change', 'input[type="radio"].chk-box', function(event) {
		$(this).parents('.chk-container:first').find('.chk-wrapper.checked').removeClass('checked');

		if ($(this).prop('checked')) {
			$(this).parents('.chk-wrapper:first').addClass('checked');
		} else {
			$(this).parents('.chk-wrapper:first').removeClass('checked');
		}
	});*/

    $(document).on("click", ".add-new-address", function (event) {
        event.preventDefault();
        if ($(this).hasClass("reverse-order")) {
            $(this)
                .parents(".acc-container:first")
                .find(".add-new-address-form")
                .slideUp();
            $(this)
                .parents(".acc-container:first")
                .find(".pre-added-addresses")
                .slideDown();
        } else {
            $(this)
                .parents(".acc-container:first")
                .find(".pre-added-addresses")
                .slideUp();
            $(this)
                .parents(".acc-container:first")
                .find(".add-new-address-form")
                .slideDown();
        }
    });

    $(document).on("click", ".step.passed", function (event) {
        event.preventDefault();
        var currentStep = getCookie("currentStep");
        var step = $(this).data("step");
        if (step >= currentStep) {
            updateProgressBar(currentStep);
            return false;
        }

        setCookie("currentStep", step, 1);
        updateProgressBar(step);
        $(".acc-container").slideUp();
        $('.acc-container[data-step-count="' + step + '"]').slideDown();
    });

    $(document).on(
        "change",
        "#address-stateId, #billingAddress-stateId, #shippingAddress-stateId",
        function (event) {
            event.preventDefault();
            if ($(this).val() == "") {
                $(this)
                    .parents("form:first")
                    .find(".country-wrapper")
                    .show()
                    .find("select")
                    .val("")
                    .find('option[value=""]')
                    .prop("selected", true);
            } else {
                var option = $(this).find("option:selected").attr("country");
                $(this)
                    .parents("form:first")
                    .find(".country-wrapper")
                    .hide()
                    .find("select")
                    .find('option[value="' + option + '"]')
                    .prop("selected", true);
            }
        }
    );
    $("#address-stateId").trigger("change");
    $("#billingAddress-stateId").trigger("change");
    $("#shippingAddress-stateId").trigger("change");

    $(document).on("change", 'input[type="radio"].chk-box', function (event) {
        $(this)
            .parents(".chk-container:first")
            .find(".chk-wrapper.checked")
            .removeClass("checked");

        if ($(this).prop("checked")) {
            $(this).parents(".chk-wrapper:first").addClass("checked");
        } else {
            $(this).parents(".chk-wrapper:first").removeClass("checked");
        }
    });

    $(document).on("submit", "form#add-to-cart", function (event) {
        event.preventDefault();

        if (!formStatus) {
            return false;
        }
        formStatus = false;

        var form = $(this);
        form.find('button[type="submit"]').addClass("active");

        var form_data = form.serializeArray();
        $.ajax({
            type: "POST",
            url: "",
            data: form_data,
            dataType: "json",
            cache: false,
        })
            .done(function (data, status, xhr) {
                $(".cart-quantity").text(data.cart.totalQty);
                resetCookie();
                $(".cart-box").load("/shop/cart/box", function () {
                    updateCsrfToken(".cart-box");
                    $(".cart-container-wrapper")
                        .stop()
                        .addClass("active")
                        .fadeIn();
                    $("html, body").animate({ scrollTop: 0 }, 700);
                });
            })
            .fail(function () {
                alert("Something went wrong. Please refresh and try again!");
            })
            .always(function () {
                form.find('button[type="submit"]').removeClass("active");
                stopProcess(form);
            });
    });

    $(document).on(
        "submit",
        "form.update-cart-box, form.update-cart",
        function (event) {
            event.preventDefault();

            if (!formStatus) {
                return false;
            }
            formStatus = false;

            var form = $(this);
            form.find(".ajax-loader").show();

            var elem = $(":focus");
            if (elem) {
                elem.parents(".item-wrapper:first").addClass("splash");
            }

            var form_data = form.serializeArray();
            $.ajax({
                type: "POST",
                url: "",
                data: form_data,
                dataType: "json",
                cache: false,
            })
                .done(function (data, status, xhr) {
                    if (elem) {
                        elem.parents(".splash").removeClass("splash");
                    }

                    if (removeSelector) {
                        removeSelector
                            .parents(".item-wrapper:first")
                            .fadeOut("400", function () {
                                $(this).remove();
                            });
                        removeSelector = "";
                    }

                    if (data.cart.totalQty == 0) {
                        $(".cart-quantity").text(data.cart.totalQty);
                        $(".update-cart-box").hide();
                        $(".update-cart").hide();
                        $(".stripe-payment-elements-submit-button").hide();
                        $(".empty-state").show();
                    } else {
                        $.each(data.cart.lineItems, function (index, val) {
                            $(".line-item-" + val.id)
                                .find(".i-qty")
                                .val(val.qty);
                            $(".line-item-" + val.id)
                                .find(".i-price")
                                .text(
                                    "$" +
                                        parseFloat(
                                            Math.round(val.price * 100) / 100
                                        ).toFixed(2)
                                );
                            $(".line-item-" + val.id)
                                .find(".i-total")
                                .text(
                                    "$" +
                                        parseFloat(
                                            Math.round(val.total * 100) / 100
                                        ).toFixed(2)
                                );
                        });
                    }

                    recalculateCart(data.cart);

                    if (window.location.pathname == "/checkout") {
                        resetCookie();
                        location.reload();
                    }
                })
                .fail(function () {
                    alert(
                        "Something went wrong. Please refresh and try again!"
                    );
                })
                .always(function () {
                    stopProcess(form);
                });
        }
    );

    /*Checkout jQueries*/
    $(document).on(
        "submit",
        "form.checkout-page-form, form#pre-added-billing-address-form, form#add-new-billing-address-form, form#pre-added-shipping-address-form, form#add-new-shipping-address-form, form#add-new-shipping-address-form-in, form#select-shipping-method",
        function (event) {
            event.preventDefault();

            if (formStatus === true) {
                formStatus = false;
            } else {
                return false;
            }

            var form = $(this);
            var id = form.attr("id");
            var form_data = form.serializeArray();
            form.find(".ajax-loader").show();

            $.ajax({
                type: "POST",
                url: "",
                data: form_data,
                dataType: "json",
                cache: false,
            })
                .done(function (data, status, xhr) {
                    form.parent().find(".w-form-fail").html("").hide();

                    if (id == "customer-information-form") {
                        if (data.error) {
                            form.parent()
                                .find(".w-form-fail")
                                .html(
                                    "<div>Error! Please make sure all required fields are filled before submitting again.</div>"
                                )
                                .show();
                            form.find("ul.errors").remove();
                            form.find("#form-input-user-common-error").after(
                                '<ul class="errors"><li>' +
                                    data.error +
                                    "</li></ul>"
                            );
                            stopProcess(form);
                        } else {
                            $("#step-2-form-wrapper").load(
                                "/shop/checkout/billing",
                                function () {
                                    setCookie("currentStep", "2", 1);
                                    updateProgressBar(2);
                                    updateCsrfToken("#step-2-form-wrapper");
                                    form.parents(".full-box-wrapper:first")
                                        .slideUp()
                                        .next(".full-box-wrapper")
                                        .slideDown();
                                    stopProcess(form);

                                    $(".cart-summary").load(
                                        "/shop/order-summary-sidebar",
                                        function () {}
                                    );
                                }
                            );
                        }
                    } else {
                        if (data.success === true || data.cart || data.model) {
                            if (data.cart) {
                                recalculateCart(data.cart);
                            }

                            if (id == "guest-information-form") {
                                $("#step-2-form-wrapper").load(
                                    "/shop/checkout/billing",
                                    function () {
                                        updateCsrfToken("#step-2-form-wrapper");
                                        stopProcess(form);
                                        nextStep(form);
                                        processBillingformJs();
                                    }
                                );
                            } else if (
                                id == "pre-added-billing-address-form" ||
                                id == "add-new-billing-address-form"
                            ) {
                                $("#step-3-form-wrapper").load(
                                    "/shop/checkout/shipping",
                                    function () {
                                        updateCsrfToken("#step-3-form-wrapper");
                                        stopProcess(form);
                                        nextStep(form);
                                        processShippingformJs();
                                        $("#step-2-form-wrapper").load(
                                            "/shop/checkout/billing",
                                            function () {
                                                processBillingformJs();
                                            }
                                        );
                                    }
                                );
                            } else if (
                                id == "pre-added-shipping-address-form" ||
                                id == "add-new-shipping-address-form"
                            ) {
                                $("#step-4-form-wrapper").load(
                                    "/shop/checkout/" + $("#step-4-form-wrapper").data("type"),
                                    function () {
                                        updateCsrfToken("#step-4-form-wrapper");
                                        stopProcess(form);
                                        nextStep(form);
                                        $("#step-2-form-wrapper").load(
                                            "/shop/checkout/billing",
                                            function () {
                                                processBillingformJs();
                                            }
                                        );
                                        $("#step-3-form-wrapper").load(
                                            "/shop/checkout/shipping",
                                            function () {
                                                processShippingformJs();
                                            }
                                        );
                                    }
                                );
                            } else if (
                                id == "add-new-shipping-address-form-in"
                            ) {
                                $("#step-3-form-wrapper").load(
                                    "/shop/checkout/shipping",
                                    function () {
                                        processShippingformJs();
                                        stopProcess(form);

                                        $("#step-2-form-wrapper").load(
                                            "/shop/checkout/billing",
                                            function () {
                                                processBillingformJs();
                                            }
                                        );
                                    }
                                );
                            } else if (id == "select-shipping-method") {
                                stopProcess(form);
                                nextStep(form);
                            }
                        } else {
                            form.parent()
                                .find(".w-form-fail")
                                .html(
                                    "<div>Error! Please make sure all required fields are filled before submitting again.</div>"
                                )
                                .show();
                            form.find("ul.errors").remove();
                            $.each(data.errors, function (index, val) {
                                form.find("#" + index.replace(".", "-")).after(
                                    '<ul class="errors"><li>' +
                                        val +
                                        "</li></ul>"
                                );
                            });

                            stopProcess(form);
                        }
                    }
                })
                .fail(function (data) {
                    if (typeof data.responseJSON.errors !== "undefined") {
                        form.parent()
                            .find(".w-form-fail")
                            .html(
                                "<div>Error! Please make sure all required fields are filled before submitting again.</div>"
                            )
                            .show();
                        form.find("ul.errors").remove();
                        $.each(data.responseJSON.errors, function (index, val) {
                            form.find("#" + index.replace(".", "-")).after(
                                '<ul class="errors"><li>' + val + "</li></ul>"
                            );
                        });

                        stopProcess(form);
                    } else {
                        alert(
                            "Something went wrong. Please refresh and try again!"
                        );
                    }
                })
                .always(function () {});
        }
    );

    $(document).on("change", "select#variant", function (event) {
        $(".sku-holder").text(
            $("select#variant").children("option:selected").data("sku")
        );

        if ($(".var-" + $(this).val()).length) {
            var index = $(".var-" + $(this).val())
                .parents(".slick-slide:first")
                .data("slick-index");
            var slider = $(".product_view");
            slider[0].slick.slickGoTo(parseInt(index));

            var slider = $(".product_tab");
            slider[0].slick.slickGoTo(parseInt(index));
        }
    });

    $(document).on("click", ".continue-step", function (event) {
        nextStep($(this));
    });

    processBillingformJs();
    processShippingformJs();

    // --------------------
    // Infinite Scroll
    // --------------------
    // $(window).on("load", function () {
        document.querySelectorAll(".infinite").forEach(function(element) {
            let id = element.dataset.id;
            let _this = element;

            let post = ".post-" + id;
            let pagination = ".next-" + id;
            let statusWrapper = ".page-load-status-" + id;
            let viewMore = ".view-more-" + id;
            let infScroll = new InfiniteScroll(_this, {
                path: pagination,
                append: post,
                history: false,
                button: viewMore,
                loadOnScroll: false,
                status: statusWrapper,
            });

            infScroll.on("last", function (event, body, path) {
                setTimeout(function () {
                    $(statusWrapper).fadeOut();
                }, 5000);
            });
        });
    // });
});

function recalculateCart(cart) {
    if (!isNaN(cart.itemSubtotal)) {
        $(".i-itemSubtotal").text(
            "$" +
                parseFloat(Math.round(cart.itemSubtotal * 100) / 100).toFixed(2)
        );
    } else {
        $(".i-itemSubtotal").text("$0.00");
    }
    if (!isNaN(cart.itemTotal)) {
        $(".i-itemTotal").text(
            "$" + parseFloat(Math.round(cart.itemTotal * 100) / 100).toFixed(2)
        );
    } else {
        $(".i-itemTotal").text("$0.00");
    }
    if (!isNaN(cart.totalShippingCost)) {
        $(".i-totalShippingCost").text(
            "$" +
                parseFloat(
                    Math.round(cart.totalShippingCost * 100) / 100
                ).toFixed(2)
        );
    } else {
        $(".i-totalShippingCost").text("$0.00");
    }
    if (!isNaN(cart.totalTax)) {
        $(".i-totalTax").text(
            "$" + parseFloat(Math.round(cart.totalTax * 100) / 100).toFixed(2)
        );
    } else {
        $(".i-totalTax").text("$0.00");
    }
    if (!isNaN(cart.totalDiscount)) {
        $(".i-totalDiscount").text(
            "$" +
                parseFloat(Math.round(cart.totalDiscount * 100) / 100).toFixed(
                    2
                )
        );
    } else {
        $(".i-totalDiscount").text("$0.00");
    }
    if (!isNaN(cart.totalPrice)) {
        $(".i-totalPrice").text(
            "$" + parseFloat(Math.round(cart.totalPrice * 100) / 100).toFixed(2)
        );
    } else {
        $(".i-totalPrice").text("$0.00");
    }
    if (!isNaN(cart.totalQty)) {
        $(".cart-quantity").text(cart.totalQty);
    } else {
        $(".cart-quantity").text("0");
    }
    if (!isNaN(cart.totalPrice)) {
        $(".w-commerce-commercecartordervalue").text("$" + cart.totalPrice);
    } else {
        $(".w-commerce-commercecartordervalue").text("$0.00");
    }
}

function processBillingformJs() {
    if ($(".add-new-address-form.billing-form").length) {
        let span = $(".add-new-address-form.billing-form")
            .parents(".acc-container:first")
            .find("span.meta-info");
        const meta = {
            selector: span.attr("data-selector"),
            namespace: span.attr("data-namespace"),
            states: JSON.parse(span.attr("data-states")),
            usedAddressFieldsByCountryCode: JSON.parse(
                span.attr("data-usedAddressFieldsByCountryCode")
            ),
        };
        let billingAddressObj = new AddressJs(meta);
    }
}

function processShippingformJs() {
    if ($(".add-new-address-form.shipping-form").length) {
        let span = $(".add-new-address-form.shipping-form")
            .parents(".acc-container:first")
            .find("span.meta-info");
        const meta = {
            selector: span.attr("data-selector"),
            namespace: span.attr("data-namespace"),
            states: JSON.parse(span.attr("data-states")),
            usedAddressFieldsByCountryCode: JSON.parse(
                span.attr("data-usedAddressFieldsByCountryCode")
            ),
        };
        let shippingAddressObj = new AddressJs(meta);
    }
}

window.resetCookie = function() {
    var currentStep = getCookie("currentStep");

    if (currentStep >= 3) {
        setCookie("currentStep", "3", 1);
        updateProgressBar(3);
    } else {
        setCookie("currentStep", "1", 1);
        updateProgressBar(1);
    }
}

window.setCookie = function(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie =
        cname + "=" + cvalue + ";" + expires + ";path=/;secure;SameSite=Strict";
}

window.getCookie = function(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function stopProcess(form) {
    $(".ajax-loader").hide();
    // form.find('.ajax-loader').hide();
    formStatus = true;
}

function nextStep(form) {
    var cnt = form.parents(".full-box-wrapper:first").data("step-count");
    setCookie("currentStep", Number(cnt) + 1, 1);
    updateProgressBar(Number(cnt) + 1);

    var next = form
        .parents(".full-box-wrapper:first")
        .next(".full-box-wrapper");
    if (!next.is(":visible")) {
        $(".acc-container").slideUp();
        next.slideDown();
    }
}

function updateProgressBar(currentStep) {
    $(".steps").children('li:not(".ignore")').removeClass("passed");
    $(".steps")
        .children('li:not(".ignore")')
        .each(function (index, el) {
            if ($(this).data("step") < currentStep) {
                $(this).addClass("passed");
            }
        });
}

function updateCsrfToken(div) {
    if ($(div).find("input[name='CRAFT_CSRF_TOKEN']").length) {
        $("input[name='CRAFT_CSRF_TOKEN']").val(
            $(div).find("input[name='CRAFT_CSRF_TOKEN']").val()
        );
    }
}

class AddressJs {
    constructor(data) {
        this.form = null;
        this.namespace = "";
        this.states = [];
        this.usedAddressFieldsByCountryCode = [];

        this.init(data);
    }

    init(data) {
        this.form = document.querySelector(data.selector);
        this.namespace = data.namespace ?? "";
        this.states = data.states ?? [];
        this.usedAddressFieldsByCountryCode =
            data.usedAddressFieldsByCountryCode ?? [];

        if (this.form) {
            let _this = this;
            this.form
                .querySelector(
                    "select[name='" + _this.translateName("countryCode") + "']"
                )
                .addEventListener("change", function (event) {
                    _this.countryChangeProcessDependentFields(event);
                });

            this.form
                .querySelector(
                    "input[name='" + _this.translateName("firstName") + "']"
                )
                .addEventListener("keyup", function (event) {
                    _this.setAddressTitle();
                });

            this.form
                .querySelector(
                    "input[name='" + _this.translateName("lastName") + "']"
                )
                .addEventListener("keyup", function (event) {
                    _this.setAddressTitle();
                });

            this.form
                .querySelector(
                    "input[name='" +
                        _this.translateName("fields[companyName]") +
                        "']"
                )
                .addEventListener("keyup", function (event) {
                    _this.setAddressTitle();
                });

            setTimeout(function () {
                _this.form
                    .querySelector("select#" + _this.translateId("countryCode"))
                    .dispatchEvent(new Event("change"));
            }, 10);
        }
    }

    hideAddressFields(selectorTemplate) {
        const fields = this.form.querySelectorAll(
            "." + selectorTemplate.replace("placeHolder", "js-address-field")
        );
        if (!fields.length) {
            return;
        }

        for (let i = 0; i < fields.length; i++) {
            fields[i].classList.add("hidden");
        }
    }

    showAddressField(fieldName, selectorTemplate) {
        const closestSelector = selectorTemplate;
        const field = this.form.querySelector(
            "#" + selectorTemplate.replace("placeHolder", fieldName)
        );
        if (!field) {
            return;
        }

        const fieldWrapper = field.closest(
            "." + closestSelector.replace("placeHolder", "js-address-field")
        );
        if (!fieldWrapper) {
            return;
        }

        fieldWrapper.classList.remove("hidden");
    }

    countryChangeProcessDependentFields(ev) {
        const countryCode = ev.target.value;
        const stateSelect = this.form.querySelector(
            "select#" + this.translateId("administrativeArea")
        );
        const stateText = this.form.querySelector(
            "#" + this.translateId("administrativeArea") + "[type=text]"
        );
        const $customFieldsContainer = this.form.querySelector(
            "." + this.translateId("js-address-custom-fields")
        );
        const selector = this.translateId("placeHolder");

        stateSelect.innerHTML = "";
        this.hideAddressFields(selector);

        if (countryCode && this.usedAddressFieldsByCountryCode[countryCode]) {
            this.usedAddressFieldsByCountryCode[countryCode].forEach(
                (usedField) => {
                    this.showAddressField(usedField, selector);
                }
            );
        }

        if ($customFieldsContainer) {
            if (countryCode) {
                $customFieldsContainer.classList.remove("hidden");
            } else {
                $customFieldsContainer.classList.add("hidden");
            }
        }

        if (
            this.states[countryCode] &&
            Object.keys(this.states[countryCode]).length
        ) {
            stateSelect.classList.remove("hidden");
            stateSelect.setAttribute(
                "name",
                this.namespace
                    ? this.translateName("administrativeArea")
                    : "administrativeArea"
            );
            stateSelect.setAttribute("required", true);
            stateText.classList.add("hidden");
            stateText.removeAttribute("name");
            stateSelect.innerHTML = "<option value>Select state...</option>";
            for (const key in this.states[countryCode]) {
                let selected =
                    key == stateSelect.dataset.selected ? "selected" : "";
                stateSelect.innerHTML += `<option value="${key}" ${selected}>${this.states[countryCode][key]}</option>`;
            }

            stateSelect
                .closest(
                    "." + selector.replace("placeHolder", "js-address-field")
                )
                .classList.remove("hidden");
            stateText
                .closest(
                    "." + selector.replace("placeHolder", "js-address-field")
                )
                .classList.add("hidden");
        } else {
            stateSelect.classList.add("hidden");
            stateSelect.removeAttribute("name");
            stateSelect.removeAttribute("required");
            stateText.classList.remove("hidden");
            stateText.setAttribute(
                "name",
                this.namespace
                    ? this.translateName("administrativeArea")
                    : "administrativeArea"
            );

            stateSelect
                .closest(
                    "." + selector.replace("placeHolder", "js-address-field")
                )
                .classList.add("hidden");
            stateText
                .closest(
                    "." + selector.replace("placeHolder", "js-address-field")
                )
                .classList.remove("hidden");
        }
    }

    setAddressTitle() {
        let addressTitle = "";

        let firstName = this.form.querySelector(
            "input[name='" + this.translateName("firstName") + "']"
        ).value;
        if (firstName) {
            addressTitle += (addressTitle != "" ? " " : "") + firstName;
        }

        let lastName = this.form.querySelector(
            "input[name='" + this.translateName("lastName") + "']"
        ).value;
        if (lastName) {
            addressTitle += (addressTitle != "" ? " " : "") + lastName;
        }

        let companyName = this.form.querySelector(
            "input[name='" + this.translateName("fields[companyName]") + "']"
        ).value;
        if (companyName) {
            addressTitle +=
                (addressTitle != "" ? " " : "") +
                (companyName ? "(" + companyName + ")" : "");
        }

        this.form.querySelector(
            "input[name='" + this.translateName("title") + "']"
        ).value = addressTitle;
    }

    translateId(input) {
        input = input.replace(/fields\[/g, "");
        input = input.replace(/\]/g, "");
        input = input.replace(/[\[\].]/g, "-");
        if (this.namespace) {
            input = this.namespace + "-" + input;
        }

        return input;
    }

    translateName(input) {
        if (!this.namespace) {
            return input;
        }

        if (input.startsWith("fields[")) {
            input = input.replace("fields[", "");
            input = input.replace("]", "");
            input = this.namespace + "[fields][" + input + "]";
        } else {
            input = this.namespace + "[" + input + "]";
        }

        return input;
    }
}

/* Script on windows load
------------------------------------------------------------------------------*/
$(window).on("load", function () {
    if ($(".product_view").length) {
        $(".product_view, .product_tab").fadeIn();

        setTimeout(function () {
            /* product slider */
            $(".product_view").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                fade: true,
                asNavFor: ".product_tab",
                infinite: true,
            });
            $(".product_tab").slick({
                slidesToShow: 5,
                slidesToScroll: 1,
                asNavFor: ".product_view",
                centerMode: true,
                centerPadding: 0,
                focusOnSelect: true,
                vertical: true,
                arrows: true,
                infinite: true,
                prevArrow:
                    '<button type="button" class="slick-prev hidden"></button>',
                nextArrow:
                    '<button type="button" class="slick-next flex flex-wrap justify-center mt-2 w-full"><svg class="xl:w-4 w-3 xl:h-4 h-3 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 7.963"><path id="slick-next" data-name="slick-next" d="M0,0V4.283L3.7,8,0,11.717V16L7.963,8Z" transform="translate(16) rotate(90)" fill="#253860" opacity="0.8"></path></svg><span class="text-xs text-blue-700 font-bold uppercase" >VIEW MORE</span></button>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            vertical: false,
                            slidesToShow: 4,
                            arrows: false,
                        },
                    },
                ],
            });
        }, 500);
    }
});

function setSlideHeight() {
    var maxHeight = 0;
    $('.hero-banner .slick-slide').each(function(){
        var slideHeight = $(this).height();
        if (slideHeight > maxHeight) {
            maxHeight = slideHeight;
        }
    });
    $('.hero-banner .slick-slide').css('height', maxHeight + 'px');
}